<template lang="pug">
  b-row.mb-5
    b-col
      machines-form(:action='action')
</template>

<script>
import MachinesForm from '@views/container/manager/machines/Form'

export default {
  name: 'manager-machines-new',
  components: {
    MachinesForm,
  },
  props: {
    action: String,
  },
}
</script>
